:root {
  /* --slideshow-indicator-width: 90px;
  --slideshow-indicator-inactive-width: 32px; */
  --slideshow-indicator-width: 40px;
  --slideshow-indicator-inactive-width: 20px;
}

/* @media all and (max-width: 450px) {
  :root {
    --slideshow-indicator-width: 60px;
    --slideshow-indicator-inactive-width: 22px;
  }
} */

.indicators {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.slideshow-indicator {
  width: var(--slideshow-indicator-inactive-width);
  transition: width 0.3s ease;
}

.slideshow-indicator.active {
  width: var(--slideshow-indicator-width);
}

.slideshow-indicator .progress-bar {
  opacity: 0;
}

.slideshow-indicator.active .progress-bar {
  opacity: 1;
  animation: progress var(--slideshow-duration) linear;
  transition: width 0.1s linear;
  transform-origin: left;
}

.SlideshowComponent,
.SlideshowComponent > * > *,
.react-slideshow-container,
.react-slideshow-wrapper,
.images-wrap.horizontal {
  height: 100%;
}

.SlideshowComponent img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.react-slideshow-container {
  width: calc(100% + 1px);
}

@keyframes progress {
  from {
    /* width: 5px; */
    transform: scaleX(0);
  }
  to {
    /* width: var(--slideshow-indicator-width); */
    transform: scaleX(1);
  }
}
