:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  /* --font-family-system: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial,
    sans-serif;

  --font-family-sans-serif: var(--font-family-system);
  --font-family-heading: 'Georgia', var(--font-family-sans-serif); */

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  --display-page-scroll-shift: 0px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* disable rotation */
/* .landscape-warning {
  display: none;
}
@media screen and (max-height: 400px) and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
  .landscape-warning {
    display: block;
  }
} */

@keyframes rotateReveal {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0);
  }
}

html,
body {
  max-width: 100vw;
  height: 100%;
}

html {
  viewport-fit: cover;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  /* height: calc(var(--vh, 1vh) * 100); */
  font-size: 15px;
  height: 100%;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

body {
  overflow-x: hidden;
  color: rgb(var(--foreground-rgb));
  background: #8e4a12;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* lottie fix */
.lottie svg {
  transform: unset !important;
}

img {
  transition: 2s;
}

/* Style for ImageSmooth component */
.img-blur {
  opacity: 1;
  filter: blur(20px);
  transform: scale(1.1);
}

.unblur {
  animation: unblur 0.5s forwards;
}

@keyframes unblur {
  from {
    opacity: 1;
    filter: blur(20px);
    transform: scale(1.1);
  }
  to {
    opacity: 0;
    filter: blur(0);
    transform: scale(1);
  }
}

.EZDrawer .EZDrawer__container {
  box-shadow: 0 0 100px 30px rgb(0 0 0 / 10%) !important;
}

/* .hideScrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  overflow-y: scroll;
}

.hideScrollbar::-webkit-scrollbar {
  display: none; 
} */

.chakra-checkbox__control {
  background-color: white;
  border: none;
  border-color: white !important;
  border-width: 1px !important;
  padding: 10px;
}

.chakra-checkbox__control[data-invalid] {
  border-color: red !important;
}

/* Planity */
.planity-component-button {
  font-family: 'commutersSansBold', sans-serif !important;
}

body::-webkit-scrollbar,
body::-webkit-scrollbar-track,
body::-webkit-scrollbar-track-piece,
body::-webkit-resizer,
body::-webkit-scrollbar-corner {
  width: 0.375rem;
  background-color: #fcf3f0;
}
body::-webkit-scrollbar-thumb {
  background-color: #cd6917;
}

@media (min-width: 768px) {
  .menu-modal-content::-webkit-scrollbar,
  .menu-modal-content::-webkit-scrollbar-track,
  .menu-modal-content::-webkit-scrollbar-track-piece,
  .menu-modal-content::-webkit-resizer,
  .menu-modal-content::-webkit-scrollbar-corner {
    width: 0.375rem;
    background-color: #fcf3f0;
  }
  .menu-modal-content::-webkit-scrollbar-thumb {
    background-color: #cd6917;
  }
}

body::before {
  content: '';
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--display-page-scroll-shift, 0px);
  background: #fcf3f0;
}

/* Helpers */

/* .chakra-container {
  border: 1px solid blue !important;
} */

/* HSW */
/*
html.smartpreview-panel-open {
  --display-page-scroll-shift: 6px !important;
}
body.smartpreview-panel-open {
  padding-right: 6px !important;
  overflow: hidden !important;
  #smartpreview {
    margin-right: var(--display-page-scroll-shift, 0px);
  }
}
#smartpreview {
  margin-right: var(--display-page-scroll-shift, 0px);
}
*/

/* Content */

.rgpd .section h2 {
  margin-top: clamp(4rem, 4.5vw, 6rem) !important;
  margin-bottom: clamp(2rem, 2.5vw, 3.5rem) !important;
}

.rgpd .section p,
.rgpd .section ul {
  padding-bottom: 1rem;
}

/* HSC */

.kustom-page-rooms #diadao-hsw-hsp-html,
.kustom-page-room #diadao-hsw-hsp-html,
.kustom-page-rooms .hi-widget-container,
.kustom-page-room .hi-widget-container,
.offer-page-details #diadao-hsw-hsp-html,
.offer-page-details .page-header,
.offer-page-details .hi-widget-container,
.offer-page-details .networks,
.offer-page-details #menu {
  display: none !important;
}
